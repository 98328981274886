import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Seo from "src/utils/Seo"
import { RichText } from "prismic-reactjs"
import Img from "gatsby-image/withIEPolyfill"
import Fade from "react-reveal/Fade"
import Breadcrumbs from "src/utils/Breadcrumbs"
import { useCookies } from "react-cookie"
import { useForm } from "react-hook-form"
import ScrollLock from "react-scrolllock"
import GatsbyLink from "src/utils/GatsbyLink"
import {
  useNetlifyForm,
  NetlifyFormProvider,
  NetlifyFormComponent,
  Honeypot,
} from "react-netlify-forms"

const ArchivePostItem = props => {
  return (
    <Link
      to={`/our-blog/${props.uid}/`}
      className="group col-span-2 text-left border-l border-gold mb-12"
      title={`${props.data.title.text}`}
    >
      <Fade>
        <Img
          fluid={props.data.featured_image.fluid}
          alt={props.data.title.text}
          className="-ml-px transition duration-500 ease-in-out group-hover:opacity-70 max-w-full h-0 pb-[60%]"
        />
        <div className="p-6 pb-0">
          <date className="text-darkgreysecond text-xs inline-block leading-normal">
            {props.data.post_date} - {props.tags.join(", ")}
          </date>
          <h2 className="text-darkgrey font-bold text-xs tracking-widest uppercase my-6 group-hover:text-gold duration-500 ease-in-out">
            {props.data.title.text}
          </h2>
          <div className="description">
            <RichText
              render={props.data.description.richText}
              serializeHyperlink={GatsbyLink}
            />
          </div>
        </div>
      </Fade>
    </Link>
  )
}

const ArchiveSelfBuilderResources = props => {
  const current = new Date()
  const nextYear = new Date()
  nextYear.setFullYear(current.getFullYear() + 1)

  const [cookie, setCookie] = useCookies(["selfbuilder_cookie"])

  function handleCookieClick() {
    setCookie("selfbuilder_cookie", true, { path: "/", expires: nextYear })
  }

  const ArchivePostsContainer = props => {
    return (
      <div className="blog-items have-description">
        <div className="grid grid-cols-1 lg:grid-cols-6 gap-6">
          {props.data.map((post, index) => {
            return <ArchivePostItem {...post.node} key={index} />
          })}
        </div>
      </div>
    )
  }

  const DownloadsListingBrochure = props => {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {props.data.map((item, index) => {
          return (
            <Link
              to={
                item.node.data.download_file
                  ? `${item.node.data.download_file.url}`
                  : ""
              }
              target="_blank"
              className="group col-span-1 text-left border-l border-gold mb-12"
              title={`${item.node.data.title.text}`}
            >
              <Fade>
                <div className="bg-white w-full relative -ml-px">
                  <Img
                    fluid={
                      item.node.data.featured_image
                        ? item.node.data.featured_image.fluid
                        : ""
                    }
                    alt={item.node.data.featured_image.alt}
                    className="absolute top-0 left-0 w-full flex justify-center items-end transition duration-500 ease-in-out group-hover:opacity-70 h-full"
                  />
                </div>
                <div className="p-6 pb-0">
                  <h2 className="text-darkgrey font-bold text-xs tracking-widest uppercase mb-4 group-hover:text-gold duration-500 ease-in-out">
                    {item.node.data.title.text}
                  </h2>
                  <div className="description text-sm">
                    <RichText
                      render={item.node.data.description.richText}
                      serializeHyperlink={GatsbyLink}
                    />
                  </div>
                  <i className="fal text-xl mt-6 text-gold group-hover:text-darkgrey duration-500 ease-in-out fa-arrow-to-bottom"></i>
                </div>
              </Fade>
            </Link>
          )
        })}
      </div>
    )
  }

  const VideosListing = props => {
    return (
      <Fade>
        <div className="grid grid-cols-1 lg:grid-cols-6 gap-6">
          {props.data.map((item, index) => {
            return (
              <div
                key={index}
                className="group col-span-2 cursor-pointer text-left border-l border-gold mb-12 pointer-cursor outline-none focus:outline-none"
                role="button"
                tabIndex={0}
                onClick={() =>
                  handleVideoClick(
                    item.node.data.link_to_video.text,
                    item.node.data.youtube_link.richText
                  )
                }
                onKeyDown={() =>
                  handleVideoClick(
                    item.node.data.link_to_video.text,
                    item.node.data.youtube_link.richText
                  )
                }
              >
                <Fade>
                  <div className="-ml-px transition duration-500 ease-in-out group-hover:opacity-70 relative">
                    {/* Disabling because it doesnt work on Safari */}
                    <img
                      src={item.node.data.featured_image.url}
                      alt={item.node.data.title.text}
                    />
                    <div className="video-icon absolute z-10">
                      <img src={"/video.svg"} alt="Play video" />
                    </div>
                  </div>
                  <div className="p-6 pb-0">
                    <h2 className="text-darkgrey font-bold text-xs tracking-widest uppercase mb-6 mt-2">
                      {item.node.data.title.text}
                    </h2>
                    <div className="description">
                      <RichText
                        render={item.node.data.description.richText}
                        serializeHyperlink={GatsbyLink}
                      />
                    </div>
                  </div>
                </Fade>
              </div>
            )
          })}
        </div>
      </Fade>
    )
  }

  const DownloadsForm = props => {
    const { register, formState: { errors }, handleSubmit } = useForm()
    const [cookies] = useCookies()
    const gclid = cookies.gclid  

    const netlify = useNetlifyForm({
      name: "brochure-download",
      honeypotName: "bot-field",
      onSuccess: (response, context) => {
        console.log("Successfully sent form data to Netlify Server")
        window.dataLayer.push({event: 'form-submission', formName: 'self-builder-resources'});
      },
    })

    const onSubmit = data => {
      netlify.handleSubmit(null, data)
      handleCookieClick()
    }

    return (
      <Fade>
        <div className="text-center mb-12">
          <h2 className="font-display text-gold rfs:text-5xl">
            Brochure Downloads
          </h2>
          <div className="w-12 separator mt-5"></div>
          <p className="max-w-2xl mt-6 m-auto">
            Download one of our detailed brochures to find out more about our
            front, internal and garage door ranges. Featuring our most popular
            designs along with technical specifications, configuration
            information, our range of woods and finishes, and available
            accessories, you can pick your perfect new door from the comfort of
            your own home.
          </p>
        </div>
        <div className="flex flex-row relative flex-wrap lg:flex-nowrap xl:mb-0 lg:mb-24">
          <div className="w-full lg:w-7/12">
            <img
              className="lg:w-100 w-full"
              alt="Brochure form background"
              src={
                "https://images.prismic.io/deuren/2e39549c-393a-47ad-9841-37ba20caa788_9fd13761-rosewood-internal-door-vario-4-6.jpg?auto=compress%2Cformat&rect=0%2C0%2C800%2C1067&w=1200&h=900"
              }
            />
          </div>
          <NetlifyFormProvider {...netlify}>
            <NetlifyFormComponent
              id="self-builder-resources-form"
              className="bg-white lg:mt-12 lg:p-16 p-6 w-full lg:w-3/5 lg:absolute lg:right-0"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Honeypot />
              <div className="flex flex-row space-x-6 mb-6">
                <div className="w-full">
                  <label className="forminputlabel" htmlFor="newsletter">
                    SELECT WHICH BROCHURES YOU’D LIKE TO DOWNLOAD{" "}
                    <span className="text-gold">*</span>
                  </label>
                  <p className="formbodytext mb-6">
                    You can select more than one door type if you wish.
                  </p>
                  <div className="flex lg:flex-row flex-col lg:space-y-0 space-y-4">
                    <div className="lg:w-1/3">
                      <label className="flex justify-start items-start gap-2">
                        <input
                          type="checkbox"
                          className="opacity-0 absolute"
                          name="door_options"
                          {...register("door_options",{ required: true })}
                        />
                        <i className="far fa-circle"></i>
                        <i className="fas fa-check-circle"></i>
                        <div className="formcheckboxlabel" htmlFor="door_options">
                          Internal Doors
                        </div>
                      </label>
                    </div>
                    <div className="lg:w-1/3">
                      <label className="flex justify-start items-start gap-2">
                        <input
                          type="checkbox"
                          className="opacity-0 absolute"
                          name="door_options"
                          {...register("door_options",{ required: true })}
                        />
                        <i className="far fa-circle"></i>
                        <i className="fas fa-check-circle"></i>
                        <div className="formcheckboxlabel" htmlFor="door_options">
                          Front Doors
                        </div>
                      </label>
                    </div>
                    <div className="lg:w-1/3">
                      <label className="flex justify-start items-start gap-2">
                        <input
                          type="checkbox"
                          className="opacity-0 absolute"
                          name="door_options"
                          {...register("door_options",{ required: true })}
                        />
                        <i className="far fa-circle"></i>
                        <i className="fas fa-check-circle"></i>
                        <div className="formcheckboxlabel" htmlFor="door_options">
                          Garage Doors
                        </div>
                      </label>
                    </div>
                  </div>
                  <span className="formerror">
                    {errors.door_options &&
                      "Please tick at least one of the above"}
                  </span>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col lg:space-x-6 lg:space-y-0 space-y-6 mb-6">
                <div className="lg:w-1/2">
                  <label htmlFor="first_name" className="forminputlabel">
                    First name <span className="text-gold">*</span>
                  </label>
                  <input
                    className="forminput"
                    type="text"
                    placeholder="First name"
                    name="first_name"
                    {...register("first_name",{ required: true })}
                  />
                  <span className="formerror">
                    {errors.first_name && "First name is required"}
                  </span>
                </div>
                <div className="lg:w-1/2">
                  <label className="forminputlabel" htmlFor="last_name">
                    Last name <span className="text-gold">*</span>
                  </label>
                  <input
                    className="forminput"
                    type="text"
                    placeholder="Last name"
                    name="last_name"
                    {...register("last_name",{ required: true })}
                  />
                  <span className="formerror">
                    {errors.last_name && "Last name is required"}
                  </span>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col lg:space-x-6 lg:space-y-0 space-y-6 mb-6">
                <div className="lg:w-1/2">
                  <label className="forminputlabel" htmlFor="email_address">
                    Email address <span className="text-gold">*</span>
                  </label>
                  <input
                    className="forminput"
                    type="email"
                    placeholder="Email address"
                    name="email_address"
                    {...register("email_address",{ required: true })}
                  />
                  <span className="formerror">
                    {errors.email_address && "Email address is required"}
                  </span>
                </div>
                <div className="lg:w-1/2">
                  <label className="forminputlabel" htmlFor="phone_number">
                    Phone number <span className="text-gold">*</span>
                  </label>
                  <input
                    className="forminput"
                    type="text"
                    placeholder="Phone number"
                    name="phone_number"
                    {...register("phone_number",{ required: true })}
                  />
                  <span className="formerror">
                    {errors.phone_number && "Phone number is required"}
                  </span>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col lg:space-x-6 lg:space-y-0 space-y-6 mb-6">
                <div className="lg:w-1/2">
                  <label className="forminputlabel" htmlFor="house_name">
                    House name/number <span className="text-gold">*</span>
                  </label>
                  <input
                    className="forminput"
                    type="text"
                    placeholder="House name/number"
                    name="house_name"
                    {...register("house_name",{ required: true })}
                  />
                  <span className="formerror">
                    {errors.house_name && "House name/number is required"}
                  </span>
                </div>
                <div className="lg:w-1/2">
                  <label className="forminputlabel" htmlFor="postcode">
                    Postcode <span className="text-gold">*</span>
                  </label>
                  <input
                    className="forminput"
                    type="text"
                    placeholder="Postcode"
                    name="postcode"
                    {...register("postcode",{ required: true })}
                  />
                  <span className="formerror">
                    {errors.postcode && "Phone number is required"}
                  </span>
                </div>
              </div>
              <div className="flex flex-row space-x-6 mb-6">
                <div className="w-full">
                  <label className="forminputlabel" htmlFor="newsletter">
                    We'd love to keep in touch
                  </label>
                  <p className="formbodytext mb-6">
                    We'd like to keep you up to date with the latest product
                    information and news from Deuren by email. We’ll always treat
                    your personal details with the utmost care and will never sell
                    them to other companies for marketing purposes. All emails
                    include an unsubscribe link. You may opt-out at any time. See
                    our Privacy Policy.
                  </p>
                  <label className="flex justify-start items-start gap-2">
                    <input
                      type="checkbox"
                      className="opacity-0 absolute"
                      name="newsletter"
                      {...register("newsletter")}
                    />
                    <i className="far fa-circle"></i>
                    <i className="far fa-check-circle"></i>
                    <div className="formcheckboxlabel" htmlFor="newsletter">
                      I agree to receive occasional emails containing the latest
                      product information and news from Deuren.
                    </div>
                  </label>
                  <span className="formerror">
                    {errors.newsletter &&
                      "Please tick the box above to submit this form"}
                  </span>
                </div>
              </div>
              <div className="flex flex-row space-x-6 mb-6">
                <div className="w-full">
                  <input
                    type="text"
                    className="hidden"
                    name="gclid"
                    value={gclid}
                    {...register("gclid")}
                  />                  
                  <input
                    className="btn cursor-pointer outline-none"
                    type="submit"
                    value="Submit"
                  />
                </div>
              </div>
            </NetlifyFormComponent>
          </NetlifyFormProvider>
        </div>
      </Fade>
    )
  }

  const crumbs = [
    { label: "Advice", href: "/advice" },
    {
      label: "Self-Builder Resources",
      href: "/advice/self-build-resources",
      active: true,
    },
  ]

  // Switch tabs when you click
  const [toggled, setToggle] = useState("Articles")
  function handleTabClick(props) {
    setToggle(props)
  }

  const [showModal, setShowModal] = useState(false)

  // We're using just one modal for the whole thing
  const [videoData, setVideoData] = useState({
    url: "",
  })

  // Make the modal switch data depending on what you've clicked
  function handleVideoClick(url, youtube_link) {
    setVideoData({
      url: url,
      youtube_link: youtube_link,
    })
    setShowModal(true)
  }

  return (
    <article>
      <Seo title={"Self-Builder Resources"} />
      <div className="py-5">
        <Breadcrumbs data={crumbs} theme="light" />
      </div>
      <section className={`bg-offwhite`}>
        <div className="container text-center pb-12">
          <Fade>
            <h1 className="font-display text-gold rfs:text-5xl">
              Self-build resources
            </h1>
            <div className="w-12 separator mt-5"></div>
            <p className="max-w-2xl mt-6 m-auto">
              We frequently work with self-builders on their dream homes,
              lending inspiration and expert advice from our 25 years’
              experience in door manufacturing. We know the highs and lows of
              self-building and are always on hand to help however we can. Take
              a look at some of our self-builder resources and get inspired
              today.
            </p>
          </Fade>
        </div>
      </section>
      <div className="w-full bg-white py-6">
        <div className="py-0 md:px-0 px-6 overflow-x-auto">
          <div className="space-x-4 w-max m-auto">
            <button
              className={`btn font-bold text-xxs whitespace-nowrap ${
                toggled === "Articles" ? "active" : ""
              }`}
              onClick={() => handleTabClick("Articles")}
            >
              Articles
            </button>
            <button
              className={`btn font-bold text-xxs whitespace-nowrap ${
                toggled === "Downloads" ? "active" : ""
              }`}
              onClick={() => handleTabClick("Downloads")}
            >
              Downloads
            </button>
            <button
              className={`btn font-bold text-xxs whitespace-nowrap ${
                toggled === "Videos" ? "active" : ""
              }`}
              onClick={() => handleTabClick("Videos")}
            >
              Videos
            </button>
          </div>
        </div>
      </div>
      <section className="bg-offwhite overflow-hidden">
        <div className="container pt-12">
          <div
            className={`${
              toggled === "Articles" ? "visible" : "invisible h-0"
            }`}
          >
            <div
              className={`transition-opacity duration-1000 ${
                toggled === "Articles" ? "opacity-100" : "opacity-0"
              }`}
            >
              <ArchivePostsContainer
                data={props.data.allPrismicBlogPost.edges}
              />
            </div>
          </div>
          <div
            className={`${
              toggled === "Downloads" ? "visible" : "invisible h-0"
            }`}
          >
            <div
              className={`transition-opacity duration-1000 ${
                toggled === "Downloads" ? "opacity-100" : "opacity-0"
              }`}
            >
              {cookie.selfbuilder_cookie && (
                // <DownloadsListing data={props.data.allPrismicDownloads.edges} />
                <DownloadsListingBrochure
                  data={props.data.allPrismicBrochure.edges}
                />
              )}
              {!cookie.selfbuilder_cookie && <DownloadsForm />}
            </div>
          </div>
          <div
            className={`${toggled === "Videos" ? "visible" : "invisible h-0"}`}
          >
            <div
              className={`transition-opacity duration-1000 ${
                toggled === "Videos" ? "opacity-100" : "opacity-0"
              }`}
            >
              <VideosListing data={props.data.allPrismicVideo.edges} />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-black">
        <div className="container text-center">
          <Fade>
            <h2 className="font-display text-gold rfs:text-5xl">
              Got a project in mind?
            </h2>
            <div className="w-12 separator mt-5"></div>
            <p className="max-w-md mt-6 m-auto">
              If you’ve got plans or are ready to start on a self-build project,
              get in touch for inspiration and expert advice to see how we can
              help.
            </p>
            <Link to="/contact/" className="btn mt-6">
              Contact the team
            </Link>
          </Fade>
        </div>
      </section>
      {/* The modal */}
      <div
        className={`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none transition duration-500 ease-in-out ${
          showModal ? "visible" : "invisible"
        }`}
      >
        <div
          className={`relative flex justify-center items-center lg:p-0 p-6 w-auto lg:h-auto lg:my-6 mx-auto z-60 md:p-8`}
        >
          <div
            className={`shadow-lg relative flex flex-col w-full lg:min-h-full bg-white outline-none focus:outline-none z-50 transition-opacity duration-500 ease-in-out ${
              showModal ? "opacity-100" : "opacity-0"
            } `}
          >
            <div className="relative">
              <button
                className="absolute z-40 cursor-pointer -top-3 h-8 w-8 rounded-full -right-3 outline-none focus:outline-none bg-white hover:text-gold"
                onClick={() => setShowModal(false)}
              >
                <i className="fal fa-times"></i>
              </button>
              {showModal ? (
                // <video
                //   className="object-cover w-full h-full outline-none focus:outline-none"
                //   autoPlay
                //   controls
                //   muted
                // >
                //   <source src={videoData.url} type="video/mp4" />
                // </video>
                <div className="video-popup-container">
                  <RichText
                    render={videoData.youtube_link}
                    serializeHyperlink={GatsbyLink}
                  />
                  {/* <div data-oembed="https://www.youtube.com/watch?v=y9j-BL5ocW8&amp;ab_channel=BobykMedia" data-oembed-type="video" data-oembed-provider="YouTube" className="embed embed-youtube">
                    <div>
                      <iframe width="200" height="113" src="https://www.youtube.com/embed/y9j-BL5ocW8?feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                    </div>
                  </div> */}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div
          className={`fixed inset-0 z-40 bg-darkgrey transition-opacity outline-none focus:outline-none duration-500 ease-in-out ${
            showModal ? "opacity-75" : "opacity-0"
          }`}
          role="button"
          tabIndex={0}
          onClick={() => setShowModal(false)}
          onKeyDown={() => setShowModal(false)}
        >
          <span className="invisible">Close modal</span>
        </div>
      </div>
      <ScrollLock isActive={showModal} accountForScrollbars={true} />
    </article>
  )
}

export default ArchiveSelfBuilderResources

export const ArchiveSelfBuilderResourcesQuery = graphql`
  query ArchiveSelfBuilderResourcesQuery {
    allPrismicBlogPost(
      filter: { tags: { eq: "Self Build" } }
      sort: { fields: data___post_date, order: DESC }
    ) {
      edges {
        node {
          id
          uid
          tags
          data {
            title {
              text
            }
            featured_image {
              alt
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
            description {
              richText
            }
            post_date(formatString: "DD MMM YYYY")
          }
        }
      }
    }
    allPrismicBrochure(filter: { tags: { eq: "Self Build" } }) {
      edges {
        node {
          data {
            title {
              text
            }
            featured_image {
              alt
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
            download_file {
              url
            }
            description {
              richText
            }
          }
          tags
        }
      }
    }
    allPrismicDownloads {
      edges {
        node {
          data {
            title {
              text
            }
            description {
              richText
            }
            document {
              url
            }
            featured_image {
              url
              alt
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
    allPrismicVideo {
      edges {
        node {
          data {
            title {
              text
            }
            description {
              richText
            }
            featured_image {
              url
              alt
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
            link_to_video {
              text
            }
            youtube_link {
              richText
              text
            }
          }
        }
      }
    }
  }
`
